import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import PackageSelection from "./PackageNames";
import usePuckEditorByPageName from "../customHooks/usePuckEditorByPageName";

const Packages = () => {
  const [broadCategoryList, setBroadCategoryList] = useState<any>([]);
  const [firstBroadCategory, setFirstBroadCategory] = useState<any>("");
  const [activeCategory, setActiveCategory] = useState<any>(
    broadCategoryList[0]?.name || ""
  );
  const [page, setPage] = useState<any>(0);
  const [broadCategoryId, setBroadCategoryId] = useState<any>("");
  const scrollRef = useRef<HTMLDivElement>(null);
  const [hover, setHover] = useState<any>(false);
  const [allText, setAllText] = useState<any>("ALL");
  const [loader, setLoader] = useState<any>(false);
  const [packageListBroadCategoryWise, setPackageListBroadCatWise] =
    useState<any>([]);
  const { puckData, puckloader } = usePuckEditorByPageName("PACKAGES");
  const pkgPuckData: any = puckData?.content?.find(
    (elem: any) => elem.type === "Package"
  );

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (allText === "ALL") {
      getAllPackages();
    } else {
      getBroadCategoryList("");
    }
  }, [allText]);

  useEffect(() => {
    getMorePosts();
  }, [allText]);

  const getBroadCategoryList = async (pageNumber: any) => {
    axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: (pageNumber - 1) * 4,
            limit: 4,
            filters: [
              {
                field: "publish",
                operator: "eq",
                value: "1",
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "broadCategoryListIncPackage",
            appName: "selfMaximized",
            folder: "packages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const newData = result?.data?.data;
          setBroadCategoryList(newData);
          setFirstBroadCategory(result?.data?.data[0]?.name);
        } else {
          setBroadCategoryList([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const getPackageListBroadCategoryWise = async (categoryId: Number) => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        broad_category_id: categoryId,
        action: "command",
        command: [
          {
            agent: "packageListBroadCategoryWise",
            appName: "selfMaximized",
            folder: "packages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setPackageListBroadCatWise(result?.data?.data);
        } else {
          setPackageListBroadCatWise([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const getAllPackages = async () => {
    axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 5000,
            filters: [
              {
                field: "publish",
                operator: "eq",
                value: "1",
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "packageList",
            appName: "selfMaximized",
            folder: "packages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setPackageListBroadCatWise(result?.data?.data);
        } else {
          setPackageListBroadCatWise([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };
  const getMorePosts = () => {
    setPage((page: any) => {
      const newPage = page + 1;
      getBroadCategoryList(newPage);
      return newPage;
    });
  };

  const styles: any = {
    container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      overflow: "hidden",
    },
    scrollContainer: {
      display: "flex",
      overflowX: "auto",
      scrollBehavior: "smooth",
      scrollbarWidth: "none",
      scrollbarHeight: "none",
      whiteSpace: "nowrap",
    },
    categoryItem: {
      padding: "5px 10px ",
      whiteSpace: "nowrap",
    },
    button: {
      cursor: "pointer",
    },
  };
  return (
    <>
      <section
        className="custom-pt-1 custom-pb-2 parallaxie position-relative journal-detail-page-wrapper h-100 ipad-padd-bottom-20"
        data-overlay="6"
      >
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col-lg-12">
              <div className="detail-page-banner-inner">
                <h1 className="mb-0 banner-heading pb-3">
                  {pkgPuckData?.props?.title
                    ? pkgPuckData?.props?.title
                    : "PACKAGES"}
                </h1>
                <p className="mb-0 banner-description text-uppercase pb-4 pb-lg-0 pb-md-0 pb-xl-0">
                  {pkgPuckData?.props?.description
                    ? pkgPuckData?.props?.description
                    : `Medcity has been present in Europe since 1990, offering
                  innovative solutions, specializing in medical services for
                  treatment of medical infrastructure.`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="page-content">
        <section className="py-0 packages__wrapper packages__wrapper__cust">
          <div className="container-fluid left-right-space">
            <div className="">
              <div
                className="blog_messonry_button d-flex justify-content-between align-items-center cust-tab-width"
                id="jornalTab"
              >
                <div style={styles.container} className="position-relative">
                  <div className="tabs-left_arrow">
                    <button
                      onClick={scrollLeft}
                      style={{
                        ...styles.button,
                        color: hover ? "white" : "grey",
                      }}
                      className=""
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-arrow-left"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.707 4.707a1 1 0 0 1 0 1.414L8.414 8l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0z" />
                      </svg>
                    </button>
                  </div>
                  <div ref={scrollRef} style={styles.scrollContainer}>
                    <button
                      style={styles.categoryItem}
                      className={`${
                        activeCategory ? "" : "active"
                      } text-uppercase`}
                      onClick={() => {
                        setAllText("ALL");
                        setActiveCategory("");
                        setPage(0);
                        setLoader(false);
                      }}
                    >
                      ALL
                    </button>

                    {broadCategoryList.map((category: any, index: any) => (
                      <button
                        key={index}
                        style={styles.categoryItem}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        className={`${
                          activeCategory && activeCategory === category.name
                            ? "active"
                            : ""
                        } text-uppercase`}
                        onClick={() => {
                          setActiveCategory(category.name);
                          setFirstBroadCategory("");
                          getPackageListBroadCategoryWise(category.id);
                          if (allText) {
                            setAllText("");
                          }
                        }}
                      >
                        {category?.name}({category?.package_count})
                      </button>
                    ))}
                  </div>
                  <div className="tabs-right_arrow">
                    <button
                      onClick={scrollRight}
                      style={{
                        ...styles.button,
                        color: hover ? "white" : "grey",
                      }}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.293 12.293a1 1 0 0 1 0-1.414L9.586 8 6.293 4.707a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PackageSelection
          packageListBroadCategoryWise={packageListBroadCategoryWise}
          // broadCategoryList={broadCategoryList}
          // broadCategoryId={broadCategoryId}
        />
      </div>
    </>
  );
};

export default Packages;
