import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import singlGrid from "../../../web/src/images/grid/Two-grid.png";
import twoGrid from "../../../web/src/images/grid/four-grid.png";
import Select from "react-select";
import CryptoJS from "crypto-js";
import useDebounce from "../customHooks/useDebounce";
import BookSpecialist from "./BookSpecialist";
import ShimmerUI from "./Shimmer";

const SingleBlogDetails: React.FC = () => {
  const { categoryid, id } = useParams();
  const [blogDetails, setBlogDetails] = useState<any>([]);
  const [doctorList, setDoctorList] = useState<any>([]);
  const [filterType, setFilterType] = useState<any>("");
  const [subFilterType, setSubFilterType] = useState<any>("");
  const [filterValue, setFilterValue] = useState<any>("");
  const [filterSidebar, setFilterSidebar] = useState<any>("");
  const [gridType, setGridType] = useState<any>("");
  const [languageOptions, setLanguageOptions] = useState<any>([]);
  const [multiFilterArray, setMultiFiltersArray] = useState<any>([]);
  const [initialVal, setIntialVal] = useState<any>(0);
  const [specialistFilter, setSpecialistFilter] = useState<any>([
    "Sort By",
    "Sex",
    "Languages Spoken",
    "Year Of Experience",
    "Rating",
  ]);
  const [yearOfExpArray, setYrsOfExpArray] = useState<any>([
    "LESS THAN 5",
    "OVER 5",
    "OVER 10",
    "OVER 15",
  ]);
  const [selectedLangOptions, setSelectedLangOptions] = useState<any>([]);
  const [payloadFilters, setPayloadFilters] = useState<any>([]);
  const [filterMenu, setFilterMenu] = useState<any>(false);
  const [isOpen, setIsOpen] = useState(false);
  const offcanvasRef = useRef<HTMLDivElement | null>(null);
  const [doctorUuid, setDoctorUuid] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const userRole = localStorage.getItem("userRole");
  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getLanguagesList();
  }, []);

  useEffect(() => {
    singleBlogDetails();
  }, [id]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        offcanvasRef.current &&
        !offcanvasRef.current.contains(event.target as Node)
      ) {
        const btn = document.getElementById("closeMenu");
        btn?.click();
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.body.style.overflow = "";
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.body.style.overflow = "";
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  let decryptedRole: any;

  if (userRole && token) {
    const decryptUserRole = (encryptedRole: any, key: any) => {
      const bytes = CryptoJS.AES.decrypt(encryptedRole, key);
      return bytes.toString(CryptoJS.enc.Utf8);
    };

    decryptedRole = decryptUserRole(userRole, "98760");
  }

  const singleBlogDetails = async () => {
    setLoader(true);
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 10,
            filters: [
              {
                field: "isActive",
                operator: "eq",
                value: 1,
              },
              {
                field: "uuid",
                operator: "eq",
                value: `${id}`,
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "categoryLists",
            appName: "selfMaximized",
            folder: "category",
          },
          {
            agent: "categoryDoctorList",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      })
      .then((result) => {
        setBlogDetails(result?.data?.data[0]);
        setDoctorList(result?.data?.data[0].doctorList);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  let filters: any = [
    {
      field: "isActive",
      operator: "eq",
      value: "1",
    },
    {
      field: "isActive",
      table: "Users",
      operator: "eq",
      value: "1",
    },
    {
      field: "uuid",
      operator: "eq",
      value: id,
    },
    {
      field: "role",
      table: "Users",
      operator: "eq",
      value: "specialist",
    },
  ];

  const getSpecialistListByFilter = async () => {
    filters.push(...payloadFilters);
    let sortFilters: any = [];

    if (
      (filterType === "Sort By" && subFilterType === "ascending") ||
      (filterType === "Sort By" && subFilterType === "descending")
    ) {
      sortFilters.push({
        field: "price",
        table: "Product",
        dir: `${
          filterType === "Sort By" && subFilterType === "ascending"
            ? "asc"
            : filterType === "Sort By" && subFilterType === "descending"
            ? "desc"
            : "asc"
        }`,
      });
    } else if (filterType === "Sort By" && subFilterType === "newest") {
      sortFilters.push({
        field: "id",
        table: "Doctors",
        dir: "desc",
      });
    } else if (filterType !== "Sort By") {
      sortFilters.push({
        field: "id",
        table: "Doctors",
        dir: "desc",
      });
    }

    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: filters,
            sort: sortFilters,
          },
        },
        action: "command",
        command: [
          {
            agent: "categoryDoctorList",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      })
      .then((result) => {
        setDoctorList(result?.data?.data);
        console.log(result?.data?.data);
        const btn = document.getElementById("closeMenu");
        btn?.click();

        setSubFilterType("");
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToBuyProductPage = (doctorId: any) => {
    setDoctorUuid((prevState: any[]) => [doctorId]);
  };

  const checkFilter = (filterType: any, subFilterType: any) => {
    if (filterType === "Languages Spoken") {
      setSelectedLangOptions(subFilterType);
    }

    setMultiFiltersArray((prevArray: any) => {
      // Filter out any existing "Sort By" and "Sex" items
      let filteredArray = prevArray.filter((filElem: any) => {
        return (
          filElem.Filter_Type !== "Sort By" &&
          filElem.Filter_Type !== "Sex" &&
          filElem.Filter_Type !== "Year Of Experience"
        );
      });

      // Handle the case for "Languages Spoken"
      if (filterType === "Languages Spoken") {
        return prevArray;
      }

      // Handle "Sort By" and "Sex" filter types
      if (
        filterType === "Sort By" ||
        filterType === "Sex" ||
        filterType === "Year Of Experience"
      ) {
        // Check if the filter is already applied, say male is checked
        const exists = prevArray.some(
          (filElem: any) =>
            filElem.Filter_Type === filterType &&
            filElem.Sub_Filter_Type === subFilterType
        );

        if (exists) {
          // If it exists, remove it
          return prevArray.filter(
            (filElem: any) =>
              filElem.Filter_Type !== filterType ||
              filElem.Sub_Filter_Type !== subFilterType
          );
        } else {
          // If it doesn't exist, add it
          filteredArray.push({
            Filter_Type: filterType,
            Sub_Filter_Type: subFilterType,
          });
          return [
            ...filteredArray,
            ...prevArray.filter(
              (filElem: any) => filElem.Filter_Type !== filterType
            ),
          ];
        }
      }

      // Handle all other filter types
      const exists = prevArray.some(
        (filElem: any) => filElem.Sub_Filter_Type === subFilterType
      );

      if (exists) {
        // If it exists, remove it
        return prevArray.filter(
          (filElem: any) => filElem.Sub_Filter_Type !== subFilterType
        );
      } else {
        // If it doesn't exist, add it
        return [
          ...prevArray,
          { Filter_Type: filterType, Sub_Filter_Type: subFilterType },
        ];
      }
    });

    console.log(subFilterType);
    setPayloadFilters((prevArray: any) => {
      const newFilter = {
        field:
          filterType === "Languages Spoken"
            ? "language"
            : filterType === "Year Of Experience"
            ? "yearofexperience"
            : filterType === "Sex"
            ? "gender"
            : filterType === "Rating"
            ? "rating"
            : null,
        operator:
          filterType === "Sex"
            ? "eq"
            : filterType === "Year Of Experience" &&
              subFilterType === "LESS THAN 5"
            ? "eq"
            : (filterType === "Year Of Experience" &&
                subFilterType === "OVER 5") ||
              subFilterType === "OVER 10" ||
              subFilterType === "OVER 15"
            ? "greteq"
            : "eq",
        table: filterType === "Rating" ? "Doctors" : "DoctorExperiences",
        value:
          filterType === "Languages Spoken"
            ? subFilterType.map((item: any) => item.label) // Array of selected languages
            : filterType === "Year Of Experience"
            ? ["OVER 5", "LESS THAN 5"].includes(subFilterType)
              ? 5
              : subFilterType === "OVER 10"
              ? 10
              : subFilterType === "OVER 15"
              ? 15
              : subFilterType
            : subFilterType,
      };

      if (newFilter.field === null) {
        return prevArray;
      }
      if (filterType === "Sex") {
        const filteredArray = prevArray.filter(
          (elem: any) => elem.field !== "gender"
        );

        return [...filteredArray, newFilter];
      }

      if (filterType === "Year Of Experience") {
        const filteredArray = prevArray.filter(
          (elem: any) => elem.field !== "yearofexperience"
        );

        return [...filteredArray, newFilter];
      }

      // Remove existing language filters
      if (filterType === "Languages Spoken") {
        const filteredArray = prevArray.filter(
          (filter: any) => filter.field !== "language"
        );

        // Create a separate filter object for each language
        const newLanguageFilters = newFilter.value.map((lang: string) => ({
          field: "language",
          operator: "contain",
          table: "Doctors",
          value: lang,
        }));

        // Return the updated array with new language filters added
        return [...filteredArray, ...newLanguageFilters];
      } else {
        // Handle other filter types
        const filterExists = prevArray.some(
          (filter: any) =>
            filter.field === newFilter.field && filter.value === newFilter.value
        );

        if (filterExists) {
          return prevArray.filter(
            (filter: any) =>
              !(
                filter.field === newFilter.field &&
                filter.value === newFilter.value
              )
          );
        } else {
          return [...prevArray, newFilter];
        }
      }
    });

    setSubFilterType(subFilterType);
    setFilterType(filterType);
    setFilterValue("");
    setFilterMenu(false);
  };

  const getLanguagesList = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        action: "command",
        command: [
          {
            agent: "languageLists",
            appName: "selfMaximized",
            folder: "build",
          },
        ],
      })
      .then((result) => {
        setLanguageOptions(result?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGrid = (gridType: any) => {
    setGridType(gridType);
  };

  const mappedOptions = languageOptions
    .sort((a: any, b: any) => a.language.localeCompare(b.language))
    .map((option: any) => ({
      value: option.id,
      label: option.language,
    }));

  const CustomClearIndicator = (props: any) => (
    <div
      {...props.innerProps}
      onClick={(e) => {
        e.stopPropagation(); // Prevent the click event from propagating
        e.preventDefault(); // Prevent the default action
        props.selectProps.onChange([], { action: "clear" }); // Clear selection
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      style={{ cursor: "pointer", padding: "0 10px" }}
    >
      {/* Your SVG here */}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 18L18 6M6 6L18 18"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );

  const renderAreaOfExp = (areaOfExp: any): React.ReactNode => {
    let filteredAreaOfExp: string[] = [];

    if (areaOfExp) {
      const parsedAreaOfExp = JSON.parse(areaOfExp);
      filteredAreaOfExp = parsedAreaOfExp.map((elem: any) => elem.category);
    }

    if (!filteredAreaOfExp.length) {
      return null;
    }

    const displayedCategories = filteredAreaOfExp.slice(0, 2); // First 2 categories
    const remainingCount =
      filteredAreaOfExp.length - displayedCategories.length;

    if (remainingCount > 0) {
      return (
        <p
          className="mb-0 specialst_degree pb-2 text-uppercase"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={filteredAreaOfExp.join(" | ")}
        >
          {displayedCategories.join(" | ")} +{remainingCount}
        </p>
      );
    }

    // If 2 or fewer categories, display them without a tooltip
    return (
      <p
        className="mb-0 specialst_degree pb-2 text-uppercase"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      >
        {filteredAreaOfExp.join(" | ")}
      </p>
    );
  };

  const renderLanguage = (language: any): React.ReactNode => {
    let filteredLanguage: any;
    if (language) {
      const parsedLang = JSON.parse(language);
      filteredLanguage = parsedLang.map((elem: any) => elem).join(" | ");
    }
    return filteredLanguage ? filteredLanguage : null;
  };

  return (
    <>
      <section
        className="detail-page-banner blog__detail-page-banner log__detail-page-banner-new journal-detail-page-wrapper h-100 py-5 my-0 my-lg-0 "
        data-overlay="6"
      ></section>

      <div className="page-content">
        <section className="py-0 explore-detail-placeholder-wrapper">
          <div className="container-fluid px-0 position-relative ">
            <div className="row">
              <div className="col-lg-12">
                <div className="featured-image-placeholder featured-image-placeholder-new">
                  {loader ? (
                    <ShimmerUI />
                  ) : (
                    <img
                      src={blogDetails ? blogDetails.image : ""}
                      alt=""
                      className="img-fluid"
                      style={{ width: "100%", height: "480px" }}
                      loading="lazy"
                    />
                  )}
                </div>

                <h3 className="explore-detail-title text-uppercase mb-0">
                  {blogDetails ? blogDetails?.description : "TEST"}
                </h3>
                <h1 className="explore-detail-title mb-0">
                  {blogDetails ? blogDetails.name : "DEMO"}
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="grid_layout_icon pt-4 pb-4">
          <div className="container-fluid left-right-space">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="grid-icos">
                  <img
                    src={singlGrid}
                    alt="two grid"
                    className="img-fluid me-2 cursor_pointer"
                    style={{ width: "20px", height: "20px" }}
                    loading="lazy"
                    onClick={() => handleGrid("col-lg-6")}
                  />
                  <img
                    src={twoGrid}
                    alt="two grid"
                    className="img-fluid cursor_pointer"
                    style={{ width: "20px", height: "20px" }}
                    loading="lazy"
                    onClick={() => handleGrid("col-lg-3")}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-0">
          <div className="container-fluid left-right-space">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center justify-content-end">
                  <span
                    className="text-white text-uppercase filter-text"
                    style={{
                      fontSize: "18px",
                      letterSpacing: "2px",

                      cursor: "pointer",
                    }}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    FILTERS
                  </span>
                  <div className="filter_dropdown">
                    <button type="button" className="images-drop-down">
                      <div>
                        <div
                          className={`offcanvas offcanvas-end`}
                          tabIndex={-1}
                          id="offcanvasRight"
                          aria-labelledby="offcanvasRightLabel"
                          style={{
                            zIndex: "999999999",
                            width: "465px",
                            letterSpacing: "1px",
                          }}
                          ref={offcanvasRef}
                        >
                          <span
                            data-bs-dismiss="offcanvas"
                            id="closeMenu"
                          ></span>
                          <div className="offcanvas-body checkbox_color">
                            <span
                              onClick={() => {
                                setPayloadFilters([]);

                                const btn =
                                  document.getElementById("closeMenu");
                                btn?.click();
                              }}
                              className="d-flex justify-content-end text-white filter-text"
                            >
                              CLOSE FILTER
                            </span>
                            {specialistFilter.map((elem: any, index: any) => (
                              <>
                                <div
                                  className="row d-flex align-items-center justify-content-start filter_sort_wrapper"
                                  key={index}
                                >
                                  <div className="col-12 filtter_btween text-start d-flex align-items-center">
                                    {elem === "Newest First" ||
                                    elem === "Oldest First" ? (
                                      <input
                                        className="form-check-input mb-3"
                                        type="checkbox"
                                        id="v-pills-home-tab"
                                        onClick={() => checkFilter(elem, "")}
                                        checked={filterType === elem}
                                      />
                                    ) : null}
                                    <label
                                      className="form-check-label mb-0 text-white text-uppercase"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {elem} :
                                    </label>
                                  </div>
                                </div>
                                {elem === "Sex" && (
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="row text-start">
                                        <div className="col-4 col-md-6">
                                          <input
                                            className="form-check-input  custom-checkbox mb-1"
                                            type="checkbox"
                                            style={{
                                              width: "16px", // Adjust the width as needed
                                              height: "16px", // Adjust the height as needed

                                              outline: "none",
                                              borderRadius: "0", // Ensure it stays square
                                            }}
                                            id="v-pills-home-tab"
                                            onClick={() =>
                                              checkFilter(elem, "male")
                                            }
                                            checked={multiFilterArray.some(
                                              (filElem: any) =>
                                                filElem.Sub_Filter_Type ===
                                                "male"
                                            )}
                                          />
                                          <label
                                            className="form-check-label ps-2 text-white "
                                            htmlFor="flexCheckDefault"
                                          >
                                            MALE
                                          </label>
                                        </div>

                                        <div className="col-4 col-md-6">
                                          <input
                                            className="form-check-input custom-checkbox mb-1"
                                            type="checkbox"
                                            id="v-pills-home-tab"
                                            style={{
                                              width: "16px", // Adjust the width as needed
                                              height: "16px", // Adjust the height as needed

                                              outline: "none",
                                              borderRadius: "0", // Ensure it stays square
                                            }}
                                            onClick={() =>
                                              checkFilter(elem, "female")
                                            }
                                            checked={multiFilterArray.some(
                                              (filElem: any) =>
                                                filElem.Sub_Filter_Type ===
                                                "female"
                                            )}
                                          />
                                          <label
                                            className="form-check-label mb-0 ps-2 text-white"
                                            htmlFor="flexCheckDefault"
                                          >
                                            FEMALE
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {elem === "Sort By" && (
                                  <div className="col-lg-6 col-12 d-flex flex-column flex-lg-row justify-content-between price">
                                    <div className="d-flex align-items-center mb-2 mb-lg-0">
                                      <input
                                        className="form-check-input custom-checkbox mb-1"
                                        type="checkbox"
                                        id="ascending-price"
                                        style={{
                                          width: "16px", // Adjust the width as needed
                                          height: "16px", // Adjust the height as needed

                                          outline: "none",
                                          borderRadius: "0", // Ensure it stays square
                                        }}
                                        onClick={() =>
                                          checkFilter(elem, "ascending")
                                        }
                                        checked={multiFilterArray.some(
                                          (filElem: any) =>
                                            filElem.Sub_Filter_Type ===
                                            "ascending"
                                        )}
                                      />
                                      <label
                                        className="form-check-label mb-0 ps-2 text-white"
                                        htmlFor="ascending-price"
                                      >
                                        ASCENDING PRICE
                                      </label>
                                    </div>
                                    <div className="ps-0 ps-lg-2 d-flex align-items-center mb-2 mb-lg-0">
                                      <input
                                        className="form-check-input custom-checkbox mb-1"
                                        type="checkbox"
                                        id="descending-price"
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                          outline: "none",
                                          borderRadius: "0",
                                        }}
                                        onClick={() =>
                                          checkFilter(elem, "descending")
                                        }
                                        checked={multiFilterArray.some(
                                          (filElem: any) =>
                                            filElem.Sub_Filter_Type ===
                                            "descending"
                                        )}
                                      />
                                      <label
                                        className="form-check-label mb-0 ps-2 text-white"
                                        htmlFor="descending-price"
                                      >
                                        DESCENDING PRICE
                                      </label>
                                    </div>
                                    <div className="ps-0 ps-lg-2  d-flex align-items-center">
                                      <input
                                        className="form-check-input custom-checkbox mb-1"
                                        type="checkbox"
                                        id="newest-first"
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                          outline: "none",
                                          borderRadius: "0",
                                        }}
                                        onClick={() =>
                                          checkFilter(elem, "newest")
                                        }
                                        checked={multiFilterArray.some(
                                          (filElem: any) =>
                                            filElem.Sub_Filter_Type === "newest"
                                        )}
                                      />
                                      <label
                                        className="form-check-label mb-0 ps-2 text-white"
                                        htmlFor="newest-first"
                                      >
                                        NEWEST FIRST
                                      </label>
                                    </div>
                                  </div>
                                )}
                                {elem === "Year Of Experience" && (
                                  <div className="row ">
                                    {yearOfExpArray.map(
                                      (yrelem: any, index: any) => (
                                        <div
                                          className="d-flex justify-content-start  col-md-4 col-6 align-items-center "
                                          key={index}
                                        >
                                          <input
                                            className="form-check-input custom-checkbox mb-1"
                                            type="checkbox"
                                            id="v-pills-home-tab"
                                            style={{
                                              width: "16px", // Adjust the width as needed
                                              height: "16px", // Adjust the height as needed
                                              appearance: "none", // Ensure custom styling is applied
                                              WebkitAppearance: "none", // For Safari and Chrome
                                              MozAppearance: "none", // For Firefox
                                              outline: "none",
                                              borderRadius: "0", // Ensure it stays square
                                              display: "inline-block",
                                              verticalAlign: "middle",
                                            }}
                                            onClick={() =>
                                              checkFilter(elem, yrelem)
                                            }
                                            checked={multiFilterArray.some(
                                              (filElem: any) =>
                                                filElem.Sub_Filter_Type ===
                                                yrelem
                                            )}
                                          />
                                          <label
                                            className="form-check-label mb-0 ps-2 text-white"
                                            htmlFor="flexCheckDefault"
                                          >
                                            {yrelem}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                                {elem === "Rating" && (
                                  <div className="row">
                                    <div
                                      className="col-lg-12 text-start"
                                      key={index}
                                    >
                                      <label
                                        className="form-check-label mb-0 text-white"
                                        htmlFor="flexCheckDefault"
                                      >
                                        <Rating
                                          size={30}
                                          onClick={(value: any) => {
                                            checkFilter(elem, value);
                                            setIntialVal(value);
                                          }}
                                          fillColor="white"
                                          emptyColor="black"
                                          SVGstorkeWidth="1px"
                                          SVGstrokeColor="white"
                                          allowFraction
                                          style={{
                                            marginBottom: "30px",
                                            paddingBottom: "30px",
                                          }}
                                          initialValue={initialVal}
                                          className="stat-rating"
                                        />
                                      </label>
                                    </div>
                                  </div>
                                )}
                                {elem === "Languages Spoken" && (
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div
                                        id="selfDropdown"
                                        className="language__wrapper mb-2"
                                      >
                                        <Select
                                          value={selectedLangOptions}
                                          menuShouldScrollIntoView={false}
                                          onChange={(value, actionMeta) => {
                                            // If the action is to clear the selection
                                            if (actionMeta.action === "clear") {
                                              setSelectedLangOptions([]);
                                              // Optionally perform any additional actions or checks here
                                              return;
                                            }

                                            // For other actions (e.g., selecting options)
                                            setSelectedLangOptions(value);
                                            checkFilter(elem, value);
                                          }}
                                          options={mappedOptions}
                                          isSearchable={true}
                                          name="language"
                                          components={{
                                            ClearIndicator:
                                              CustomClearIndicator,
                                          }}
                                          className="text-dark text-uppercase letter_spacing"
                                          isMulti
                                          styles={{
                                            control: (provided, state) => ({
                                              ...provided,
                                              fontSize: "14px",
                                              minHeight: "20px",
                                              height: "100%",
                                              width: "100%",
                                              maxWidth: "400px",
                                              color: "inherit",
                                              backgroundColor: "#FFF",
                                              letterSpacing: "1px",
                                              border: state.isFocused
                                                ? "2px solid black"
                                                : "2px solid transparent",
                                              boxShadow: state.isFocused
                                                ? "none"
                                                : "none",
                                              "&:hover": {
                                                borderColor: "#FFF",
                                              },
                                            }),
                                            option: (provided, state) => ({
                                              ...provided,
                                              display: "flex",
                                              justifyContent: "start",
                                              color:
                                                state.isFocused ||
                                                state.isSelected
                                                  ? "white"
                                                  : "black",
                                              backgroundColor: state.isFocused
                                                ? "#000"
                                                : "#fff",
                                            }),
                                            singleValue: (provided) => ({
                                              ...provided,
                                              color: "#000",
                                            }),
                                            input: (provided) => ({
                                              ...provided,
                                              color: "#000",
                                            }),
                                            placeholder: (provided) => ({
                                              ...provided,
                                              color: "black",
                                              display: "flex",
                                              justifyContent: "start",
                                            }),
                                            menu: (provided) => ({
                                              ...provided,
                                              backgroundColor: "#000",
                                            }),
                                          }}
                                          placeholder="SEARCH YOUR LANGUAGE"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                            <span
                              data-bs-dismiss="offcanvas"
                              id="closeMenu"
                              aria-label="Close"
                            ></span>
                            <div className="row">
                              <div
                                className="d-flex flex-column flex-lg-row flex-md-row justify-content-between mt-4"
                                style={{
                                  position: "absolute",
                                  bottom: "20px",
                                  left: "0",
                                  right: "0",
                                }}
                              >
                                <button
                                  className="btn btn-light col-12 col-lg-5 mb-2 mb-lg-0 mb-1 filter-button"
                                  style={{
                                    letterSpacing: "1px",
                                    borderRadius: "0",
                                  }}
                                  onClick={() => getSpecialistListByFilter()}
                                >
                                  APPLY FILTERS (
                                  {multiFilterArray.length +
                                    selectedLangOptions.length}
                                  )
                                </button>
                                <button
                                  className="btn btn-light btn-md col-12 col-lg-5 filter-button"
                                  style={{
                                    letterSpacing: "1px",
                                    borderRadius: "0",
                                  }}
                                  onClick={() => {
                                    setMultiFiltersArray([]);
                                    setPayloadFilters([]);
                                    setIntialVal(0);
                                    setSelectedLangOptions([]);
                                    setSubFilterType("");
                                    setFilterType("");
                                    setFilterValue("");
                                    singleBlogDetails();
                                  }}
                                >
                                  CLEAR
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-2 explore__details__wrapper_one pb-0">
          <div className="container-fluid">
            <div className="row">
              {doctorList.length !== 0 && doctorList !== null ? (
                doctorList.map((elem: any) => (
                  <div
                    className={`${
                      gridType === "col-lg-6" ? "col-lg-6" : "col-lg-3"
                    } position-relative px-0 overflow-hidden `}
                    key={elem.id}
                  >
                    <div className="explore_more_wrapper_one explore_more_wrapper_one-new">
                      <div className="explore-thumb">
                        <div className="cs-image">
                          <img
                            src={elem.profile}
                            alt="pregency"
                            className="w-100"
                            style={{
                              height: `${
                                gridType === "col-lg-6" ? "500px" : "300px"
                              }`,
                              minHeight: `${
                                gridType === "col-lg-6" ? "500px" : "300px"
                              }`,
                              overflow: "hidden",
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div className="explore-content">
                        <h3 className="explore-heading mb-0 text-light ">
                          {elem.name.toUpperCase()}
                        </h3>
                        {renderAreaOfExp(
                          elem.specialistExprience.length !== 0
                            ? elem.specialistExprience[0].areaofexpertise
                            : null
                        )}
                        <span
                          onClick={() => {
                            goToBuyProductPage(elem.id);
                            const userInfo = localStorage.getItem("userInfo");
                            const parsedUserInfo =
                              typeof userInfo === "string"
                                ? JSON.parse(userInfo)
                                : userInfo;
                            if (
                              (parsedUserInfo &&
                                parsedUserInfo?.role === "admin") ||
                              (parsedUserInfo &&
                                parsedUserInfo?.role === "specialist")
                            ) {
                              localStorage.clear();
                            }
                          }}
                          className="btn book-btn"
                          id="bookBtn"
                        >
                          BOOK
                        </span>
                      </div>
                      <div className="hidden-content-when-hover-wrapper grassmorphism-pattern">
                        <h3 className="explore-heading mb-0">
                          {elem.name.toUpperCase()}
                        </h3>
                        {renderAreaOfExp(
                          elem.specialistExprience.length !== 0
                            ? elem.specialistExprience[0].areaofexpertise
                            : null
                        )}

                        <p className="specialist-year-of-experience mb-0 text-uppercase">
                          {elem.specialistExprience.length !== 0 &&
                          elem.specialistExprience
                            ? "ABOVE " +
                              elem.specialistExprience[0].yearofexperience +
                              " YEARS"
                            : null}
                        </p>
                        <p className="mb-0 specialist-langauge pb-2 text-uppercase">
                          {renderLanguage(
                            elem.specialistExprience.length !== 0
                              ? elem.specialistExprience[0].language
                              : null
                          )}
                        </p>
                        <p className="mb-0 specialist-langauge pb-2 text-uppercase">
                          {elem?.specialistExprience.length !== 0 ? (
                            "$" + elem?.specialistExprience[0].min_price
                          ) : (
                            <span className="text-light letter_spacing">
                              NO PRODUCTS AVAILABLE
                            </span>
                          )}{" "}
                        </p>
                        <span
                          onClick={() => {
                            goToBuyProductPage(elem.id);
                            const userInfo = localStorage.getItem("userInfo");
                            const parsedUserInfo =
                              typeof userInfo === "string"
                                ? JSON.parse(userInfo)
                                : userInfo;
                            if (parsedUserInfo?.role === "admin") {
                              localStorage.clear();
                            }
                          }}
                          className="btn book-btn"
                          id="bookBtn"
                        >
                          BOOK
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-lg-12 left-right-space text-center">
                  <span className="d-block text-white letter_spacing text-uppercase">
                    No Data Available
                  </span>
                </div>
              )}
            </div>
          </div>
        </section>
        <BookSpecialist doctorUuid={doctorUuid} result={""} />
      </div>
    </>
  );
};

export default SingleBlogDetails;
