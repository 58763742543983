import React, { useEffect, useState } from "react";
import usePuckEditorByPageName from "../../customHooks/usePuckEditorByPageName";
interface BookedApp {
  selectedProductUuid: string;
  selectedModeType: string;
  selectedPlan: number;
  subDuration: number;
  selectedSlot: any;
  specialistData: string;
  selectedMonth: string;
  nextProductBookedType: string;
  selectedAppointments: any;
  onSelectedBookedtype: any;
  alertUser: boolean;
  checkSelectedBookedType: any;
}

const BookedAppointment: React.FC<BookedApp> = React.memo(
  ({
    specialistData,
    selectedPlan,
    onSelectedBookedtype,
    checkSelectedBookedType,
    alertUser,
  }) => {
    const [bookedType, setBookedType] = useState<any>({});
    const [userSelectedTypes, setUserSelectedType] = useState<any>(false);

    const { puckData, puckloader } = usePuckEditorByPageName(
      "SUBSCRIPTION APPOINTMENT TYPE"
    );

    const subAppointmentType: any =
      puckData &&
      puckData.content?.find((elem: any) => elem.type === "SubAppointmentType");

    useEffect(() => {
      onSelectedBookedtype(bookedType);
      checkSelectedBookedType(userSelectedTypes);
    }, [bookedType]);

    return (
      <div>
        {" "}
        <div className="please-select-text">
          <div className="row">
            <div className="col-lg-12 d-flex">
              <div
                className="mr-5 text-white "
                data-bs-target="#specialistDetails"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                <button
                  className="btn book-btn book-btn-new min-height-40"
                  type="button"
                >
                  {subAppointmentType?.props?.backbutton
                    ? subAppointmentType?.props?.backbutton
                    : "BACK"}{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h2 className="text-white text-uppercase text-left">
                {subAppointmentType?.props?.subscriptionAppointment
                  ? subAppointmentType?.props?.subscriptionAppointment
                  : "SUBSCRIPTION APPOINTMENT"}
              </h2>
            </div>
          </div>
          <p className="text-white p explore-heading-one text-uppercase">
            {subAppointmentType?.props?.bookSlotText
              ? subAppointmentType?.props?.bookSlotText
              : "Book slot for - DR."}
            {specialistData?.toUpperCase()}
          </p>
          <hr className="text-white  custom-hr"></hr>
          <p
            className={`text-${alertUser ? "danger" : "light"} letter_spacing`}
          >
            {subAppointmentType?.props?.appointmentStatus
              ? subAppointmentType?.props?.appointmentStatus
              : " SELECT YOUR APPOINTMENT STATUS"}
          </p>
          <div className="subscription-appointment-one please-select-text d-lg-flex justify-content-lg-center">
            <ul className="book-appointment-check-group d-lg-flex flex-row gap-4 ps-0">
              {Array.from({ length: selectedPlan }).map((_, groupIndex) => (
                <li
                  key={groupIndex}
                  className="list-style-none list__group d-flex flex-column align-items-center p-3 rounded border text-white mb-3"
                  style={{ minWidth: "150px" }}
                >
                  <p className="fw-bold text-white slot__title mb-0 pb-2">
                    {groupIndex + 1}
                    <sup className="text-uppercase">
                      {groupIndex + 1 === 1
                        ? "st"
                        : groupIndex + 1 === 2
                        ? "nd"
                        : groupIndex + 1 === 3
                        ? "rd"
                        : "th "}
                    </sup>{" "}
                    <span className="ps-1">APPOINTMENT</span>
                  </p>
                  <div className="d-flex flex-column gap-2">
                    {[
                      `${
                        subAppointmentType?.props?.bookNowText
                          ? subAppointmentType?.props?.bookNowText
                          : "BOOK NOW"
                      }`,
                      `${
                        subAppointmentType?.props?.bookLaterText
                          ? subAppointmentType?.props?.bookLaterText
                          : "BOOK LATER"
                      }`,
                    ].map((option, optionIndex) => (
                      <div
                        key={`${groupIndex}-${optionIndex}`}
                        className="form-check d-flex align-items-center text-white book__text"
                      >
                        <input
                          className="form-check-input custom__radio"
                          type="radio"
                          name={`flexRadioDefault-${groupIndex}`}
                          id={`flexRadioDefault-${groupIndex}-${optionIndex}`}
                          onClick={() => {
                            setBookedType((prevState: any) => ({
                              ...prevState,
                              [groupIndex + 1]: optionIndex + 1,
                            }));
                            setUserSelectedType(false);
                          }}
                        />
                        <label
                          className="form-check-label ps-3 mb-0"
                          htmlFor={`flexRadioDefault-${groupIndex}-${optionIndex}`}
                        >
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
);

export default BookedAppointment;
