import { Modal } from "bootstrap";
import React, { useEffect, useState } from "react";
import { format, startOfWeek, endOfMonth } from "date-fns";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import moment from "moment";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import axios from "axios";

// Define a TypeScript interface for the doctor data
interface SpecialistExperience {
  education?: string;
}

interface Doctor {
  name: string;
  profile: string;
  specialistExprience?: SpecialistExperience[];
}

interface DoctorListProps {
  doctorList: { doctorList: Doctor[] }[];
  appointmentDuration: any;
  appointmentMode: string;
  categoriesUnderPackage: any;
  activeCategoryUuid: any;
  activeCategoryId: any;
  closeMainModal: any;
  onCloseMainModalChange: any;
  highlightRemainingCatId: any;
  handleRemainingCartId: any;
  packageUuid: any;
  selectedTotalCategoryIds: any;
  handleSelectedCategoryIds: any;
}

interface Slot {
  uuid: string;
  calendarDate: string;
  doctorId: number;
  StartTime: string;
  EndTime: string;
  Duration: string;
  calendarDay: string;
  isBooked: number;
  dname: string;
}

interface GroupedSlots {
  [day: string]: Slot[];
}

const PackageCategoriesDrList: React.FC<DoctorListProps> = React.memo(
  ({
    doctorList,
    appointmentDuration,
    appointmentMode,
    categoriesUnderPackage,
    activeCategoryUuid,
    activeCategoryId,
    packageUuid,
    closeMainModal,
    highlightRemainingCatId,
    handleRemainingCartId,
    onCloseMainModalChange,
    selectedTotalCategoryIds,
    handleSelectedCategoryIds,
  }) => {
    // Extract the doctor list from the props
    const [specialistData, setSpecialistData] = useState<any>([]);
    const [slots, setSlots] = useState<any>([]);
    const [selectedSlot, setSelectedSlot] = useState<any>("");
    const [ipAddress, setIpAddress] = useState<any>("");
    const [cartId, setCartId] = useState<any>("");
    const today = new Date();
    const [startDate, setStartDate] = useState<any>(startOfWeek(today));
    const [endDate, setEndDate] = useState<any>(endOfMonth(today));
    const [rangeSelected, setRangeSelected] = useState<any>(false);
    const [selectedMonth, setSelectedMonth] = useState<any>(
      format(today, "MMMM")
    );
    const [modeType, setModeType] = useState<any>(appointmentMode);
    const location = useLocation();
    const [bookStatus, setBookStatus] = useState<any>("bookNow");
    const [doctorId, setDoctorId] = useState<any>("");
    const [catListOfPackage, setAllCategoriesOfPackage] = useState<any>([]);
    const [localCloseModal, setlocalCloseModal] = useState<any>(closeMainModal);
    const [remainingCatIds, setRemainingCategoryIds] = useState<any>(
      highlightRemainingCatId
    );
    const [userSelectedCategoryIds, setUserSelectedCategoryIds] = useState<any>(
      selectedTotalCategoryIds
    );
    const [productDataDrWise, setProductDataDrWise] = useState<any>([]);
    const [showBookSlotButton, setShowBookSlotButton] = useState<any>(false);
    const [showDrSlots, setShowDrSlots] = useState<any>(false);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const drList = doctorList?.[0]?.doctorList;
    const totalCat = categoriesUnderPackage && categoriesUnderPackage.length;
    const [drSelectedCatWise, setDrSelectedCatWise] = useState<any>([]);
    const [packageLoader, setPackageLoader] = useState<boolean>(false);

    useEffect(() => {
      setBookStatus("bookNow");
    }, [activeCategoryId]);
    useEffect(() => {
      productBySpecCategory();
    }, [activeCategoryUuid, doctorId]);

    useEffect(() => {
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          setIpAddress(data.ip);
        })
        .catch((error) => {
          console.error("Error fetching IP address:", error);
        });
    }, []);

    const handleRangeSelection = async (selectedRange: any) => {
      if (selectedRange) {
        setRangeSelected(true);
      }
      if (Array.isArray(selectedRange) && selectedRange.length === 2) {
        const [startDate, endDate] = selectedRange;
        setStartDate(startDate);
        setEndDate(endDate);
        await getSpecialistBookingSlots(startDate, endDate);
      } else {
        console.error("Invalid date range selected");
      }
    };

    async function getSpecialistDetailsById(doctorUuid: any) {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            filter: {
              filter: {
                logic: "and",
                offset: 0,
                limit: 1,
                filters: [
                  {
                    field: "uuid",
                    operator: "eq",
                    table: "Doctors",
                    value: doctorUuid,
                  },
                ],
                sort: [
                  {
                    field: "id",
                    table: "Users",
                    dir: "desc",
                  },
                ],
              },
            },
            menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
            action: "command",
            command: [
              {
                agent: "specialistListsData",
                appName: "selfMaximized",
                folder: "specialist",
              },
            ],
          }
        );

        if (result?.data?.statusCode === 200) {
          setSpecialistData(result?.data?.data[0]);
        } else {
          setSpecialistData([]);
        }
      } catch (err) {
        return err;
      }
    }

    useEffect(() => {
      const today = new Date();
      const startDate: any = startOfWeek(today);
      const endDate: any = endOfMonth(today);
      setStartDate(startDate);
      setEndDate(endDate);
      getSpecialistBookingSlots(startDate, endDate);
    }, [specialistData?.id, doctorId, activeCategoryId]);

    const handleMonthChange = ({ activeStartDate }: any) => {
      const monthName = format(activeStartDate, "MMMM"); // Get the month name
      setSelectedMonth(monthName);
    };

    const passSlots = (selectedAppointment: any) => {
      const isSlotAlreadySelected = drSelectedCatWise.some(
        (elem: any) =>
          elem?.Date === selectedAppointment?.calendarDate &&
          elem?.StartTime === selectedAppointment?.StartTime
      );

      if (isSlotAlreadySelected) {
        toast.info("THIS SLOT IS ALREADY SELECTED", {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="60"
              height="60"
              viewBox="0 0 30 30"
            >
              <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
            </svg>
          ),
          className: "custom-toast",
          bodyClassName: "custom-toast",
        });
        return;
      }

      setSelectedSlot(selectedAppointment);
    };

    const handleBookOption = (type: any) => {
      if (type === "bookLater" || type === "bookNow") {
        let object: any = {
          productId: productDataDrWise && `${productDataDrWise[0]?.id}`,
          doctorId: productDataDrWise && `${productDataDrWise[0]?.doctorid}`,
          Duration: appointmentDuration === 30 ? "0.50" : "1.0",
          StartTime: type !== "bookNow" ? null : selectedSlot?.StartTime,
          EndTime: type !== "bookNow" ? null : selectedSlot?.EndTime,
          Day: type !== "bookNow" ? null : selectedSlot?.calendarDay,
          Date: type !== "bookNow" ? null : selectedSlot?.calendarDate,
          bookingSlotId:
            type !== "bookNow" ? null : `${selectedSlot?.bookingSlotId}`,
          categoryid: `${activeCategoryId}`,
          timezone:
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            "Canada/Central",
          mode_type: modeType,
          bookStatus: type === "bookNow" ? "1" : "0",
          meta_type: "package",
        };

        setDrSelectedCatWise((prevObj: any) => {
          if (!Array.isArray(prevObj)) prevObj = [];

          return [
            ...prevObj.filter(
              (elem: any) => elem.categoryid !== object.categoryid
            ),
            object,
          ];
        });
      }
    };

    useEffect(() => {
      if (selectedSlot) {
        let object: any = {
          productId: productDataDrWise && `${productDataDrWise[0]?.id}`,
          doctorId: productDataDrWise && `${productDataDrWise[0]?.doctorid}`,
          Duration: appointmentDuration === 30 ? "0.50" : "1.0",
          StartTime: bookStatus !== "bookNow" ? null : selectedSlot?.StartTime,
          EndTime: bookStatus !== "bookNow" ? null : selectedSlot?.EndTime,
          Day: bookStatus !== "bookNow" ? null : selectedSlot?.calendarDay,
          Date: bookStatus !== "bookNow" ? null : selectedSlot?.calendarDate,
          bookingSlotId:
            bookStatus !== "bookNow" ? null : `${selectedSlot?.bookingSlotId}`,
          categoryid: `${activeCategoryId}`,
          timezone:
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            "Canada/Central",
          mode_type: "video",
          bookStatus: bookStatus === "bookNow" ? "1" : "0",
          meta_type: "package",
        };

        setDrSelectedCatWise((prevObj: any) => {
          if (!Array.isArray(prevObj)) prevObj = [];

          return [
            ...prevObj.filter(
              (elem: any) => elem.categoryid !== object.categoryid
            ),
            object,
          ];
        });
      }
    }, [activeCategoryId, selectedSlot?.StartTime]);

    const getSpecialistBookingSlots = async (
      newStartDate?: any,
      newEndDate?: any
    ) => {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            filter: {
              filter: {
                logic: "and",
                offset: 0,
                limit: 500,
                filters: [
                  {
                    field: "calendarDate",
                    operator: "between",
                    table: "BookingCalendar",
                    value: moment(newStartDate || startDate).format(
                      "YYYY-MM-DD"
                    ),
                    opr: "AND",
                    value2: moment(newEndDate || endDate).format("YYYY-MM-DD"),
                  },
                  {
                    field: "doctorId",
                    operator: "eq",
                    table: "BookingCalendar",
                    value: specialistData?.id,
                  },
                  {
                    field: "Duration",
                    operator: "eq",
                    table: "BookingCalendar",
                    value: parseFloat(
                      appointmentDuration === 30 ? "0.50 " : "1.00"
                    ).toFixed(2),
                  },
                ],
                sort: [
                  {
                    field: "calendarDate",
                    table: "BookingCalendar",
                    dir: "asc",
                  },
                ],
              },
            },
            action: "command",
            command: [
              {
                agent: "specialistCalendarBooking",
                appName: "selfMaximized",
                folder: "order",
              },
            ],
          }
        );

        if (result?.data?.code === 1) {
          const responseData: Slot[] = result?.data?.data?.response || [];

          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const now = moment.tz(userTimezone);

          const getSlotDateTime = (startTime: string): moment.Moment => {
            return moment
              .tz(
                startTime.replace(/:000Z$/, ":00Z"),
                "YYYY-MM-DDTHH:mm:ssZ",
                "UTC"
              )
              .tz(userTimezone);
          };

          const filteredSlots = responseData.filter((slot) => {
            const slotDateTime = getSlotDateTime(slot.StartTime);

            return slot.isBooked === 0 && slotDateTime.isAfter(now);
          });

          const groupedSlots: GroupedSlots = filteredSlots.reduce(
            (acc: GroupedSlots, slot: Slot) => {
              const slotDate = getSlotDateTime(slot.StartTime).format(
                "YYYY-MM-DD"
              );
              acc[slotDate] = [...(acc[slotDate] || []), slot];
              return acc;
            },
            {}
          );

          const filteredSlotsArray = Object.entries(groupedSlots)
            .map(([day, slots]: [string, Slot[]]) => ({
              dayOfWeek: moment(day, "YYYY-MM-DD")
                .tz(userTimezone)
                .format("dddd"),
              slots,
            }))
            .filter((daySlots) => daySlots.slots.length > 0);

          setSlots(filteredSlotsArray);
        } else {
          toast.error("Something went wrong");
        }
      } catch (err) {
        toast.error("Something went wrong");
      }
    };

    const productBySpecCategory = async () => {
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          doctorId: doctorId,
          categoryUuid: activeCategoryUuid,
          isActive: 1,
          action: "command",
          command: [
            {
              agent: "productBySpecCategory",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            setProductDataDrWise(result?.data?.data);
            setShowBookSlotButton(true);
          } else {
            setProductDataDrWise([]);
          }
        })
        .catch((err) => {
          return toast.error("Something went wrong", err);
        });
    };

    const checkAllCategoriesAreSelected = () => {
      if (!selectedSlot && !showBookSlotButton) {
        return toast.info("PLEASE SELECT SLOT FIRST TO PROCEED!", {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="60"
              height="60"
              viewBox="0 0 30 30"
            >
              <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
            </svg>
          ),
          className: "custom-toast",
          bodyClassName: "custom-toast",
        });
      }
      setSelectedSlot("");
      if (showBookSlotButton) {
        setShowDrSlots(true);
        setShowBookSlotButton(false);
      } else if (catListOfPackage && catListOfPackage.length === totalCat - 1) {
        createCart();
      } else {
        let remainingCategoryIds = categoriesUnderPackage.filter(
          (category: any) => {
            if (category.uuid === activeCategoryUuid) return false;

            const isInCatList = catListOfPackage.some(
              (categoryId: any) => category.uuid === categoryId
            );
            return !isInCatList;
          }
        );

        setAllCategoriesOfPackage((prevState: any) => [
          ...prevState.filter((id: any) => id !== activeCategoryUuid),
          activeCategoryUuid,
        ]);

        setRemainingCategoryIds(remainingCategoryIds);
        handleRemainingCartId(remainingCategoryIds);

        const modal = document.getElementById("dismissModal");
        if (modal) {
          setlocalCloseModal(true);
          onCloseMainModalChange(true);
          modal.click();
          setShowDrSlots(false);
          setShowBookSlotButton(true);
          setSlots([]);
          setStartDate("");
          setEndDate("");
        }
      }
    };

    useEffect(() => {
      setUserSelectedCategoryIds(catListOfPackage.length);
      handleSelectedCategoryIds(catListOfPackage.length);
    }, [catListOfPackage?.length]);

    const showExpData = (type: string) => {
      const language =
        specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
          ? specialistData?.DoctorExperiences[0].language
          : "";
      const areaOfExpertise =
        specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
          ? specialistData?.DoctorExperiences[0].areaofexpertise
          : "";
      const title =
        specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
          ? specialistData?.DoctorExperiences[0].title
          : "";
      let parsedLanguage: any;
      let parsedAreaOfExp: any;

      if (language && areaOfExpertise) {
        parsedLanguage = JSON.parse(language);
        parsedAreaOfExp = JSON.parse(areaOfExpertise);
      }

      if (parsedLanguage && type === "language") {
        return parsedLanguage.join("|");
      }
      if (parsedAreaOfExp && type === "areaofexpertise") {
        return parsedAreaOfExp.map((elem: any) => elem.category).join(" | ");
      }
      return title;
    };

    const localstorageCartId: any = localStorage.getItem("cartId");
    let data = localStorage.getItem("userInfo");
    let userInfo = null;
    if (typeof data === "string") {
      try {
        userInfo = JSON.parse(data);
      } catch (error) {
        console.error("Error parsing userInfo:", error);
      }
    } else {
      userInfo = data;
    }
    let lastActiveCartId = userInfo ? userInfo.lastActiveCart : null;
    const userCartID =
      typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;

    const createCart = async () => {
      if (
        !localstorageCartId ||
        (userCartID && userCartID.lastActiveCart !== null) ||
        (userCartID && userCartID.lastActiveCart !== undefined)
      ) {
        axios
          .post(`${process.env.REACT_APP_API}/pipeline`, {
            ip: ipAddress,
            action: "command",
            command: [
              {
                agent: "createCart",
                appName: "selfMaximized",
                folder: "order",
              },
            ],
          })
          .then((result) => {
            if (result?.data?.statusCode === 200) {
              setCartId(result?.data?.data?.cartId);
              localStorage.setItem("cartId", result?.data?.data?.cartId);
              packageAddToCart(result?.data?.data?.cartId);
            } else {
              setCartId("");
            }
          })
          .catch((err) => {
            return toast.error("Something went wrong", err);
          });
      } else {
        packageAddToCart("");
      }
    };

    const renderAreaOfExp = (areaOfExp: any): React.ReactNode => {
      let filteredAreaOfExp: string[] = [];

      if (areaOfExp) {
        const parsedAreaOfExp = JSON.parse(areaOfExp);
        filteredAreaOfExp = parsedAreaOfExp.map((elem: any) => elem.category);
      }

      if (!filteredAreaOfExp.length) {
        return null;
      }

      const displayedCategories = filteredAreaOfExp.slice(0, 2); // First 2 categories
      const remainingCount =
        filteredAreaOfExp.length - displayedCategories.length;

      if (remainingCount > 0) {
        return (
          <p
            className="mb-0 specialst_degree pb-2 text-uppercase"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={filteredAreaOfExp.join(" | ")}
          >
            {displayedCategories.join(" | ")} +{remainingCount}
          </p>
        );
      }

      // If 2 or fewer categories, display them without a tooltip
      return (
        <p
          className="mb-0 specialst_degree pb-2 text-uppercase"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
        >
          {filteredAreaOfExp.join(" | ")}
        </p>
      );
    };

    const renderLanguage = (language: any): React.ReactNode => {
      let filteredLanguage: any;
      if (language) {
        const parsedLang = JSON.parse(language);
        filteredLanguage = parsedLang.map((elem: any) => elem).join(" | ");
      }
      return filteredLanguage ? filteredLanguage : null;
    };

    const packageAddToCart = async (cartId: any) => {
      setPackageLoader(true);
      axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          cartId:
            lastActiveCartId && lastActiveCartId !== null
              ? lastActiveCartId
              : localstorageCartId
              ? localstorageCartId
              : cartId,
          packageId: packageUuid,
          ip: ipAddress,
          qty: 1,
          organizationId: 1,
          packageType: "Package",
          metadata: drSelectedCatWise,
          action: "command",
          command: [
            {
              agent: "packageAddToCart",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        })
        .then(async (result) => {
          if (result?.data?.statusCode === 200) {
            setPackageLoader(false);
            window.location.href = "/cart";
          }
        })
        .catch((err) => {
          setPackageLoader(false);
          return toast.error(`SOMETHING WENT WRONG ${err}`, {
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="60"
                height="60"
                viewBox="0 0 30 30"
              >
                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
              </svg>
            ),
            className: "custom-toast",
            bodyClassName: "custom-toast",
          });
        });
    };

    return (
      <div className="container my-5 padd-0-cust">
        <h5 className="text-white text-start letter_spacing">
          BOOKING CATEGORY {catListOfPackage.length}/{totalCat}
        </h5>
        <p className="text-white text-start letter_spacing">
          SELECT SPECIALIST - UNDER THIS CATEGORY
        </p>

        <div className="row m-0">
          {drList && drList.length > 0 ? (
            drList.map((doctor: any, index: any) => (
              <div className="col-lg-3 col-md-6 p-0" key={index}>
                <div className="custom-card custom-card-new">
                  <div className="image-container image-container-v1 explore_more_wrapper_one explore_more_wrapper_one-v1">
                    <img
                      src={doctor?.profile || "placeholder.jpg"}
                      className="custom-card-img custom-card-img-new"
                      alt={doctor?.name || "Doctor"}
                    />

                    <div className="explore-content text-start">
                      <h3 className="explore-heading mb-0 text-light">
                        {" "}
                        DR.{doctor?.name || "Unknown"}
                      </h3>
                      <p className="mb-0 specialst_degree pb-2 text-uppercase">
                        {renderAreaOfExp(
                          doctor?.specialistExprience.length !== 0
                            ? doctor?.specialistExprience[0].areaofexpertise
                            : null
                        )}
                      </p>
                      <span
                        className="btn book-btn"
                        id="bookBtn"
                        onClick={() => {
                          const modalElement =
                            document.getElementById("bookSlotPopUp");
                          if (modalElement) {
                            const modal = new Modal(modalElement);
                            modal.show();
                            getSpecialistDetailsById(doctor?.doctorUUID);
                            setDoctorId(doctor?.id);
                          } else {
                            console.error("Modal element not found");
                          }
                        }}
                      >
                        BOOK
                      </span>
                    </div>
                    <div className="common text-start">
                      <h3 className="explore-heading mb-0 ">
                        {" "}
                        DR.{doctor?.name || "Unknown"}
                      </h3>
                      <p className="mb-0 specialst_degree text-uppercase">
                        {renderAreaOfExp(
                          doctor.specialistExprience.length !== 0
                            ? doctor.specialistExprience[0].areaofexpertise
                            : null
                        )}
                      </p>
                      <p className="mb-0 specialist-gender text-uppercase">
                        {doctor?.gender}
                      </p>
                      <p className="specialist-year-of-experience mb-0 text-uppercase">
                        {doctor?.specialistExprience.length !== 0 &&
                        doctor?.specialistExprience
                          ? "EXP - ABOVE " +
                            doctor?.specialistExprience[0]?.yearofexperience +
                            " YEARS"
                          : null}
                      </p>
                      <p className="mb-0 specialist-langauge pb-2 text-uppercase">
                        {renderLanguage(
                          doctor?.specialistExprience.length !== 0
                            ? doctor?.specialistExprience[0].language
                            : null
                        )}
                      </p>
                      <p className="mb-0 specialist-langauge pb-2 text-uppercase">
                        STARTING AT -{" "}
                        {doctor?.specialistExprience.length !== 0 ? (
                          doctor?.specialistExprience[0].min_price + "$"
                        ) : (
                          <span className="text-light letter_spacing">
                            NO PRODUCTS AVAILABLE
                          </span>
                        )}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-white text-uppercase letter_spacing">
              No specialists available under this category.
            </p>
          )}
        </div>

        <div
          className="modal p-0 gloabl-model-settings"
          id="bookSlotPopUp"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel3"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-full modal-xl modal-dialog-centered">
            <div className="modal-content user_response--popup p-4 p-md-5 p-lg-5 p-xl-5 position-relative">
              <div className="modal-body text-center p-0 mt-lg-7">
                <>
                  {!showDrSlots && (
                    <div className="row pb-2 mobile-flex-wrap">
                      <div className="col max-width-225 height-219">
                        <div className="specialist-image specialist-image-box">
                          <img
                            src={specialistData?.profile}
                            alt=""
                            className="img-fluid rounded"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="subscription-group subscription-group-new">
                          <ul className="ps-0">
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Name <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase font-size-24">
                                    {specialistData?.name}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Gender <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    {specialistData?.gender}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    PROFESSIONAL <span></span> TITLE{" "}
                                    <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    {specialistData &&
                                    specialistData?.DoctorExperiences &&
                                    specialistData?.DoctorExperiences.length
                                      ? specialistData?.DoctorExperiences[0]
                                          .title
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Area of Expertise{" "}
                                    <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase font-size-20">
                                    {showExpData("areaofexpertise")}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    BIO <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Bio info
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Experience <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="text-white mb-0 text-uppercase explore-heading-one mb-0 text-uppercase">
                                    {specialistData.length !== 0 &&
                                      specialistData?.DoctorExperiences[0]
                                        .yearofexperience}{" "}
                                    YEARS EXPERIENCE
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 mb-0-custom text-uppercase">
                                    Language <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 mb-0-custom text-uppercase">
                                    {showExpData("language")}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="cust-hr mt-2">
                        <hr className="text-white"></hr>
                      </div>

                      <div className="explore-heading-one text-white mb-0 mb-0-custom text-uppercase text-start mt-lg-2">
                        <h4 className="h5">APPOINTMENT DETAILS</h4>
                        <div className="text-light">
                          {productDataDrWise?.length !== 0 ? (
                            productDataDrWise
                              .filter(
                                (elem: any) =>
                                  elem.duration ===
                                  (appointmentDuration === 30 ? "0.50" : "1.00")
                              )
                              .map((elem: any) => (
                                <>
                                  {" "}
                                  <div className="subscription-group subscription-group-new subscription-group-new-v1">
                                    <ul className="ps-0">
                                      <li>
                                        <div className="subscription-group-inner">
                                          <div className="subscription-part-one">
                                            <p className="explore-heading-one text-white mb-0 text-uppercase">
                                              {" "}
                                              CATEGORY{" "}
                                              <span className="colon">:</span>
                                            </p>
                                          </div>
                                          <div className="subscription-part-two">
                                            <p className="explore-heading-one text-white mb-0 text-uppercase">
                                              {elem?.shorttitle}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="subscription-group-inner">
                                          <div className="subscription-part-one">
                                            <p className="explore-heading-one text-white mb-0 text-uppercase">
                                              DURATION
                                              <span className="colon">:</span>
                                            </p>
                                          </div>
                                          <div className="subscription-part-two">
                                            <p className="explore-heading-one text-white mb-0 text-uppercase">
                                              {elem?.duration === "0.50"
                                                ? "30 MIN"
                                                : "60 MIN"}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="subscription-group-inner">
                                          <div className="subscription-part-one">
                                            <p className="explore-heading-one text-white mb-0 text-uppercase">
                                              PRICE
                                              <span className="colon">:</span>
                                            </p>
                                          </div>
                                          <div className="subscription-part-two">
                                            <p className="explore-heading-one text-white mb-0 text-uppercase">
                                              $ {elem?.price}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </>
                              ))
                          ) : (
                            <span className="text-light letter_spacing align-items-center">
                              PRODUCTS NOT AVAILABLE
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {showDrSlots && (
                    <>
                      <label
                        className="form-check-label mb-0 text-white explore-heading-two text-left w-100"
                        htmlFor="exampleCheck1"
                      >
                        <span className="d-block h4 text-start mb-0">
                          SINGULAR APPOINTMENT OPTIONS
                        </span>
                        <span className="available-slot d-block h6 text-start">
                          AVAILABLE SLOTS FOR - DR.{" "}
                          {specialistData?.name?.toUpperCase()}
                        </span>
                      </label>
                      <div className="book-select-options w-100 text-start">
                        <div className="book-select-option-inner pb-2">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              onChange={() => {
                                setBookStatus("bookNow");
                                handleBookOption("bookNow");
                              }}
                              checked={bookStatus === "bookNow"}
                            />
                            <label
                              className="form-check-label text-white explore-heading-two"
                              htmlFor="inlineRadio1"
                            >
                              <span className="ps-2"> BOOK NOW</span>
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              onChange={() => {
                                setBookStatus("bookLater");
                                handleBookOption("bookLater");
                              }}
                              checked={bookStatus === "bookLater"}
                            />
                            <label
                              className="form-check-label text-white explore-heading-two"
                              htmlFor="inlineRadio2"
                            >
                              <span className="ps-2">BOOK LATER</span>
                            </label>
                          </div>
                          <label className="form-check-label mb-0 text-white explore-heading-two text-left w-100">
                            <span className="available-slot d-block h6 text-start text-uppercase">
                              NOTE - If you choose to book later, please ensure
                              to select a slot within the next 30 days.
                            </span>
                          </label>
                        </div>
                        {bookStatus === "bookNow" ? (
                          <div className="row border-top m-0 ">
                            <div className="col-md-4 border-end pt-4 pb-4 pe-lg-4 pe-md-4 pe-0 ps-0 react-calendar-outer">
                              <h6 className="mb-0 text-uppercase text-white explore-heading-two pb-2">
                                Please select the date before we proceed
                              </h6>
                              <Calendar
                                selectRange={true}
                                onChange={handleRangeSelection}
                                value={[startDate, endDate]}
                                onActiveStartDateChange={handleMonthChange} // Handle month change
                              />
                            </div>
                            <div className="col-md-8 ps-0 ps-md-4 ps-lg-4 pe-0 pt-4 pb-4">
                              <h5 className="h6 text-uppercase text-white explore-heading-two explore-heading-two-box">
                                Available time slots for the month of{" "}
                                {selectedMonth}
                              </h5>
                              <div className="book-overflow">
                                <div className="slot-outer text-uppercase text-white">
                                  <div className="slot-inner row g-3">
                                    {slots.map((slot: any, index: any) => (
                                      <div
                                        key={index}
                                        className="d-flex flex-wrap gap-4  justify-content-between mt-3 mb-3"
                                      >
                                        <h3 className="text-white explore-heading-two flex-100 mb-0 line-height-20 mt-3">
                                          {slot.dayOfWeek}
                                        </h3>
                                        {slot?.slots
                                          .sort((a: any, b: any) => {
                                            const timeA = moment(
                                              a.StartTime.replace(
                                                /:000Z$/,
                                                ":00Z"
                                              ), // Fix extra milliseconds
                                              "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
                                            ).tz(userTimezone);

                                            const timeB = moment(
                                              b.StartTime.replace(
                                                /:000Z$/,
                                                ":00Z"
                                              ), // Fix extra milliseconds
                                              "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
                                            ).tz(userTimezone);

                                            // Prioritize AM before PM
                                            if (
                                              timeA.format("A") !==
                                              timeB.format("A")
                                            ) {
                                              return timeA.format("A") === "AM"
                                                ? -1
                                                : 1;
                                            }

                                            // Sort by time within the same period
                                            return timeA.diff(timeB);
                                          })

                                          .map((elem: any) => (
                                            <div
                                              className="slot-box slot-box-new col-md-6 cursor_pointer"
                                              key={elem.uuid}
                                              onClick={() => {
                                                passSlots(elem);
                                              }}
                                            >
                                              <div
                                                className="slot-box-inner border pt-2 pb-2 ps-3 pe-3"
                                                style={{
                                                  backgroundColor:
                                                    selectedSlot === elem
                                                      ? "white"
                                                      : "",
                                                  color:
                                                    selectedSlot === elem
                                                      ? "black"
                                                      : "",
                                                }}
                                              >
                                                <h5 className="mb-0 explore-heading-two pb-0">
                                                  AVAILABILITY |{" "}
                                                  {elem?.Duration} HR
                                                </h5>
                                                <p
                                                  className="mb-0 explore-heading-two pb-0"
                                                  style={{
                                                    color:
                                                      selectedSlot === elem
                                                        ? "black"
                                                        : "white",
                                                  }}
                                                >
                                                  FROM -{" "}
                                                  {userTimezone &&
                                                    moment
                                                      .tz(
                                                        elem?.StartTime.replace(
                                                          /:000Z$/,
                                                          ":00Z"
                                                        ),
                                                        userTimezone
                                                      )
                                                      .format("hh:mm A")}{" "}
                                                  <span className="to">TO</span>{" "}
                                                  -{" "}
                                                  {userTimezone &&
                                                    moment
                                                      .tz(
                                                        elem?.EndTime.replace(
                                                          /:000Z$/,
                                                          ":00Z"
                                                        ),
                                                        userTimezone
                                                      )
                                                      .format("hh:mm A")}
                                                </p>

                                                <p
                                                  className="mb-0 explore-heading-two pb-0"
                                                  style={{
                                                    color:
                                                      selectedSlot === elem
                                                        ? "black"
                                                        : "white",
                                                  }}
                                                >
                                                  {elem?.calendarDate}{" "}
                                                  <span className="day-name-right floar-right">
                                                    {userTimezone &&
                                                      moment
                                                        .tz(
                                                          elem.StartTime.replace(
                                                            /:000Z$/,
                                                            ":00Z"
                                                          ),
                                                          "UTC"
                                                        )
                                                        .tz(userTimezone)
                                                        .format("dddd")}
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    ))}
                                  </div>
                                </div>

                                <hr className="custom-hr custom-hr-white text-white"></hr>
                                {slots.length === 0 && (
                                  <h4 className="text-white text-center letter_spacing text-uppercase">
                                    No slots available for selected date{" "}
                                  </h4>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                </>
              </div>
              <div
                className="cancel__pop__icon"
                data-bs-dismiss="modal"
                id="closeSlotsModal"
                onClick={() => {
                  setShowDrSlots(false);
                }}
              >
                <i className="fa-solid fa-xmark text-white"></i>
              </div>

              <div className="submit-btn-custom w-100 d-flex justify-content-end">
                <button
                  className="btn book-btn book-btn-new d-block mb-0 mt-0 min-height-40"
                  type="button"
                  onClick={checkAllCategoriesAreSelected}
                  disabled={packageLoader}
                >
                  {showBookSlotButton
                    ? "BOOK SLOT"
                    : catListOfPackage &&
                      catListOfPackage.length === totalCat - 1
                    ? "ADD TO CART"
                    : "BOOK NEXT SPECIALIST"}
                </button>
                <input
                  data-bs-dismiss="modal"
                  id="dismissModal"
                  type="hidden"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

PackageCategoriesDrList.displayName = "PackageCategoriesDrList";

export default PackageCategoriesDrList;
